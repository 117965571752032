import React from 'react'
import { Link } from 'gatsby'
import { Segment, Container, Header, Button, Image } from 'semantic-ui-react'
import Layout from '../components/layout'
import PageHeader from '../components/PageHeader'
import { FormattedMessage } from 'gatsby-plugin-intl'

const Notfound = ({ intl }) => (
  <Layout>
    <div className="IndexPage">
      <PageHeader>
        <Header inverted as="h1">
          <Header.Content>
            <FormattedMessage id="title-404" />
            <Header.Subheader>
              <FormattedMessage id="subheader-404" />
            </Header.Subheader>
          </Header.Content>
        </Header>
        <Button as={Link} to="/" secondary>
          <FormattedMessage id="button-404" />
        </Button>
      </PageHeader>
      <Segment as="section" vertical>
        <Container>
          <Image
            src="https://media.giphy.com/media/3o7aTskHEUdgCQAXde/giphy.gif"
            centered
            rounded
          />
        </Container>
      </Segment>
    </div>
  </Layout>
)

export default Notfound
